
@media (min-width: 400px) and (max-width: 600px) {
    .live-prices.data-row, .live-prices.data-row button{
        font-size: 0.8rem;
    }

}

@media (min-width: 300px) and (max-width: 399.9px) {
    .live-prices.data-row, .live-prices.data-row button{
        font-size: 0.6rem;
    }

}

@media (min-width: 600px) and (max-width: 650px) {
    .live-prices.data-row button{
        font-size: 0.7rem;
    }

}