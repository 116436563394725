
@media  all and (max-width: 900px) {
    .response-font-size{
        font-size: 14px;
    }
}
@media  all and (max-width: 800px) {
    .response-font-size{
        font-size: 12px;
    }
}
@media  all and (min-width: 600px) and (max-width: 800px) {
    .symbol-name {
        display: none;
    }
}