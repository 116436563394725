

@media (max-width: 320px) {
    .currencies-table.custom-font-size{
        font-size: 9px;
    }
}

@media (min-width: 320.05px) and (max-width: 360px) {
    .currencies-table.custom-font-size{
        font-size: 11px;
    }
}

@media (min-width: 360.05px) and (max-width: 400px) {
    .currencies-table.custom-font-size{
        font-size: 12px;
    }
}

@media (min-width: 400.05px) and (max-width: 420px) {
    .currencies-table.custom-font-size{
        font-size: 14px;
    }
}

@media (min-width: 420.05px) and (max-width: 450px) {
    .currencies-table.custom-font-size{
        font-size: 15px;
    }
}

@media (min-width: 450.05px) {
    .currencies-table.custom-font-size{
        font-size: 16px;
    }
}
