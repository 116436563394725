.ul_section_terms {
    padding-right: 1rem;

}

.ul_section_terms li {
    font-weight: 400;
    font-size: 16px;
    color: #B7BDC6;
    margin: .75rem 0;
    list-style-type: disc;

}

.ul_section_terms li::marker {
    color: #FCD535;
}

.ul_section_terms_counted li{
    list-style:persian;

}