
.tabs_three_tab_wage .muirtl-r5mpu3-MuiButtonBase-root-MuiTab-root.Mui-selected {
    background-color: #FCD535;
    color: #1B1B1D !important;
}

@media screen and (max-width:560px) {
 .tabs_three_tab_wage .MuiTabs-flexContainer.muirtl-heg063-MuiTabs-flexContainer {
    flex-direction: column;
 }   
 .tabs_three_tab_wage .MuiTabs-flexContainer.muirtl-heg063-MuiTabs-flexContainer button  {
    margin-bottom: .5rem;
 } 
}

