.file_uploader {
    min-width: 90% !important;
    max-width: 100% !important;
    background: rgba(206, 212, 218, 0.04);
    border: 1px dashed #B7BDC6 !important;
    border-radius: 10px;
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.dmajdp .file-types {
    display: none;
}