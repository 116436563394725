.search-input-faq .muirtl-z4rnkg-MuiInputBase-input-MuiOutlinedInput-input {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.background_boxes {
    background: linear-gradient(to bottom,
            #212226 0%,
            #212226 30%,
            #1B1B1D 30%,
            #1B1B1D 100%);
}

