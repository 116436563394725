@import url(./fontiran.css);
html, body {
  font-family: IRANSansX;
  margin: 0;
  padding: 0;
  border-radius: 0;
}
h1, h2, h3, h4, h5, h6,input, textarea, p, span, div, form, table, tr, th, td {
  font-family: IRANSansX;
}

.iransans{
  font-family: IRANSansX !important;
}

.iransans-fa-num{
  font-family: IRANSansXFaNum !important;
  font-weight: 300;
}