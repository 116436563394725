.accardion-summary-guid .Mui-expanded  {
    color: #FCD535 ;
}

.accardion-summary-guid .muirtl-19hn6xk-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
}

.tabs-accardion-guid-sections span.MuiTabs-indicator.muirtl-vxrabi-MuiTabs-indicator {
    display: none !important;
    width: 0 !important;
}

.MuiTabs-root.MuiTabs-vertical.muirtl-1d68lol-MuiTabs-root {
    border: none !important;
}

.tabs-accardion-guid-sections{
    display: flex;
    align-items: center !important;
}
 .muirtl-1ptkyku-MuiPaper-root-MuiAccordion-root:before{
    height: 0 !important;
}

.accardion_parent:before{
    height: 0 !important;
}